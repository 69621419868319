import { createContext, useState } from 'react';

export const CostContext = createContext({
    cost: null,
    setCost: () => null,
});

export const CostProvider = ({ children }) => {
    const [cost, setCost] = useState({});
    const value = { cost, setCost };
    return (
        <CostContext.Provider value={value}>{children}</CostContext.Provider>
    );
};
