import { Container, Navbar } from 'react-bootstrap';
import './navigation.styles.scss';

export const Navigation = () => {
    return (
        <Navbar bg="dark" expand="lg">
            <Container>
                <Navbar.Brand href="#home" className="muchochucho-brand">
                    Mucho chucho
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
};
