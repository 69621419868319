import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { CostContext } from '../../context/cost.context';

export const Costs = () => {
    const { cost } = useContext(CostContext);
    const calculateTotalCost = listCost => {
        let totalCost = 0;
        Object.keys(listCost).forEach(key => {
            totalCost += listCost[key];
        });
        totalCost += totalCost > 0 ? 80 : 0;
        return totalCost;
    };
    const calculateMinimumPrice = listCost => {
        const totalCost = calculateTotalCost(listCost);
        return Math.ceil(totalCost * 1.5 * 1.16 * 1.05);
    };
    const calculateNormalPrice = listCost => {
        const totalCost = calculateTotalCost(listCost);
        return Math.ceil(totalCost * 2 * 1.16 * 1.05);
    };
    return (
        <>
            <Table>
                <tbody>
                    <tr>
                        <th>Costo Cubierta</th>
                        <td>{cost?.top | 0}</td>
                    </tr>
                    <tr>
                        <th>Costo Lateral</th>
                        <td>{cost?.side | 0}</td>
                    </tr>
                    <tr>
                        <th>Costo Base</th>
                        <td>{cost?.base | 0}</td>
                    </tr>
                    <tr>
                        <th>Zipper</th>
                        <td>{cost?.zipper | 0}</td>
                    </tr>
                    <tr>
                        <th>Costo Total</th>
                        <td>{calculateTotalCost(cost)}</td>
                    </tr>
                    <tr>
                        <th>Precio minimo</th>
                        <td>{calculateMinimumPrice(cost)}</td>
                    </tr>
                    <tr>
                        <th>Precio Normal</th>
                        <td>{calculateNormalPrice(cost)}</td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
};
