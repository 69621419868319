export default {
    s: {
        top: {
            l: 63,
            w: 43,
        },
        base: {
            l: 63,
            w: 43,
        },
        side: {
            w: 13,
            l: 210,
        },
        zipper: 90,
    },
    m: {
        top: {
            l: 79,
            w: 53,
        },
        base: {
            l: 79,
            w: 53,
        },
        side: {
            w: 13,
            l: 260,
        },
        zipper: 120,
    },
    l: {
        top: {
            l: 94,
            w: 71,
        },
        base: {
            l: 94,
            w: 71,
        },
        side: {
            w: 15,
            l: 325,
        },
        zipper: 140,
    },
    x: {
        top: {
            l: 114,
            w: 92,
        },
        base: {
            l: 114,
            w: 92,
        },
        side: {
            w: 17,
            l: 408,
        },
        zipper: 155,
    },
};
