import { useContext } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { CostContext } from '../../context/cost.context';
import Sizes from '../../Utils/Sizes';

export const Forms = () => {
    const { setCost } = useContext(CostContext);

    const calculateArea = sizeSelected => {
        const size = Sizes[sizeSelected];

        return {
            top: size.top.w * size.top.l,
            base: size.base.w * size.base.l,
            side: size.side.w * size.side.l,
            zipper: size.zipper,
        };
    };

    const calculateCostArea = (wide, price) => {
        return price / Math.ceil(wide * 100);
    };

    const handleSubmit = form => {
        form.preventDefault();
        const inputs = form.target.elements;
        const values = {};
        Object.keys(inputs).forEach(key => {
            let input = inputs[key];
            if (input.type !== 'submit') {
                values[input.ariaLabel] = input.value;
            }
        });

        const area = calculateArea(values.BedSize);

        const costTopByCM = calculateCostArea(values.WTop, values.PTop);
        const costBaseByCM = calculateCostArea(values.WBase, values.PBase);
        const costSideByCM = calculateCostArea(values.WSide, values.PSide);
        const costZipperByCM = values.PZipper / 100;

        const costCalculated = {
            top: Math.ceil(area.top * costTopByCM),
            base: Math.ceil(area.base * costBaseByCM),
            side: Math.ceil(area.side * costSideByCM),
            zipper: Math.ceil(area.zipper * costZipperByCM),
        };
        setCost(costCalculated);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h2>Tama&ntilde;o de cama</h2>
            <Form.Select aria-label="BedSize">
                <option>Selecciona</option>
                <option value="s">Chica</option>
                <option value="m">Mediana</option>
                <option value="l">Grande</option>
                <option value="x">Extra Grande</option>
            </Form.Select>
            <h2>Tela Cubierta</h2>
            <InputGroup>
                <InputGroup.Text className="col-6">
                    Ancho de tela
                </InputGroup.Text>
                <Form.Control
                    className="col-6"
                    aria-label="WTop"
                    type="number"
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text className="col-6">
                    Precio por metro
                </InputGroup.Text>
                <Form.Control
                    className="col-6"
                    aria-label="PTop"
                    type="number"
                />
            </InputGroup>
            <h2>Tela Lateral</h2>
            <InputGroup>
                <InputGroup.Text className="col-6">
                    Ancho de tela
                </InputGroup.Text>
                <Form.Control
                    className="col-6"
                    aria-label="WSide"
                    type="number"
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text className="col-6">
                    Precio por metro
                </InputGroup.Text>
                <Form.Control
                    className="col-6"
                    aria-label="PSide"
                    type="number"
                />
            </InputGroup>
            <h2>Tela Base</h2>
            <InputGroup>
                <InputGroup.Text className="col-6">
                    Ancho de tela
                </InputGroup.Text>
                <Form.Control
                    className="col-6"
                    aria-label="WBase"
                    type="number"
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text className="col-6">
                    Precio por metro
                </InputGroup.Text>
                <Form.Control
                    className="col-6"
                    aria-label="PBase"
                    type="number"
                />
            </InputGroup>
            <h2>Cierre</h2>
            <InputGroup>
                <InputGroup.Text className="col-6">Precio</InputGroup.Text>
                <Form.Control
                    className="col-6"
                    aria-label="PZipper"
                    type="number"
                />
            </InputGroup>
            <Button type="submit" className="col-12 mt-4" variant="dark">
                Calcular
            </Button>
        </Form>
    );
};
