import { Col, Container, Row } from 'react-bootstrap';
import './App.scss';
import { Costs } from './components/cost/costs.component';
import { Forms } from './components/forms/forms.components';
import { Navigation } from './components/navigation/navigation.component';
import { CostProvider } from './context/cost.context';

function App() {
    return (
        <>
            <Navigation />
            <CostProvider>
                <Container>
                    <Row className="justify-content-center mt-2 mb-4">
                        <Col xs="11" sm="8" md="6" lg="5" xl="4">
                            <Forms />
                            <hr />
                            <Costs />
                        </Col>
                    </Row>
                </Container>
            </CostProvider>
        </>
    );
}

export default App;
